export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Establishments',
    route: 'establishments',
  },
  {
    title: 'Facilities',
    route: 'facilities',
  },
  {
    title: 'Employees',
    route: 'employees',
    icon: 'UsersIcon',
  },
  {
    title: 'Course Management',
    route: 'course-management',
    icon: 'HomeIcon',
  },
  {
    title: 'Calendar',
    route: 'calendar',
    icon: 'CalendarIcon',
  },
  {
    title: 'Courses',
    route: 'courses',
  },
  {
    title: 'Course Dates',
    route: 'course-dates',
  },
  {
    title: 'Events',
    route: 'events',
  },
  {
    title: 'Communication',
    route: 'communication',
  },
  {
    title: 'Leads',
    route: 'leads',
  },
  {
    title: 'Tickets',
    route: 'tickets',
  },
  {
    title: 'Checkins',
  },
  {
    title: 'Substitute Requests',
    route: 'substitute-requests',
  },
  {
    title: 'Teams',
    route: 'teams',
  },
  {
    title: 'News',
    route: 'news',
    icon: 'InfoIcon',
  },
  {
    title: 'Feedback',
    route: 'feedback',
    icon: 'MailIcon',
  },
  {
    title: 'Statistics',
    route: 'statistics',
    icon: 'PieChartIcon',
  },
  {
    title: 'Worklouds',
    route: 'worklouds',
  },
  {
    title: 'Public page',
    route: 'public-page',
  },
  {
    title: 'Advertisements',
    route: 'advertisements',
  },
  {
    header: 'System',
  },
  {
    title: 'Settings',
    route: 'settings',
    icon: 'SettingsIcon',
  },
]
