<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ user.full_name || user.username }}
        </p>
        <span class="user-status">{{ user.email }}</span>
      </div>
      <b-avatar
        size="40"
        variant="light-primary"
        badge
        :src="user.avatar_thumbnail_url"
        class="badge-minimal"
        badge-variant="success"
      />
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      :to="{ name: 'profile' }"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>

    <b-dropdown-item
      :to="{ name: 'profile.edit' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="EditIcon"
        class="mr-50"
      />
      <span>Edit profile</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      :to="{ name: 'faqs' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HelpCircleIcon"
        class="mr-50"
      />
      <span>FAQs</span>
    </b-dropdown-item>

    <b-dropdown-item
      :to="{ name: 'settings' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="onSwitch"
    >
      <feather-icon
        size="16"
        icon="RepeatIcon"
        class="mr-50"
      />
      <span>Switch</span>
    </b-dropdown-item>
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="onLogout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BAvatar, BDropdownDivider, BDropdownItem, BNavItemDropdown,
} from 'bootstrap-vue'
import { AUTH_LOGOUT } from '@/modules/auth/store/action-types'

export default {
  name: 'NavbarUserDropdown',
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  computed: {
    user() {
      return this.$store.getters.getProfile
    },
  },
  methods: {
    onLogout() {
      this.$store.dispatch(AUTH_LOGOUT)
    },
    onSwitch() {
      this.$router.push({ name: 'organization-choose' })
    },
  },
}
</script>

<style scoped>

</style>
