<template>
  <div v-if="myOrganization">
    <b-navbar-nav class="nav">
      <b-button
        variant="primary"
      >
        {{ myOrganization.name }}
      </b-button>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BNavbarNav,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'NavbarOrganizationPicker',
  components: {
    BNavbarNav,
    BButton,
  },
  computed: {
    myOrganization() {
      return this.$store.getters.getMyOrganization
    },
  },
}
</script>

<style scoped>

</style>
