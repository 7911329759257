<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <NavbarOrganizationPicker />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <dark-Toggler class="d-none d-lg-block" />
      <navbar-search-bar />
      <NavbarUserDropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import NavbarOrganizationPicker from '@/layouts/components/NavbarOrganizationPicker.vue'
import NavbarSearchBar from '@/layouts/components/NavbarSearchBar.vue'
import NavbarUserDropdown from './NavbarUserDropdown.vue'

export default {
  components: {
    NavbarSearchBar,
    NavbarOrganizationPicker,
    BLink,
    BNavbarNav,
    NavbarUserDropdown,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
